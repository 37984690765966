@-webkit-keyframes spin {
    to {
        stroke-dashoffset: -264;
    }
}
@keyframes spin {
    to {
        stroke-dashoffset: -264;
    }
}
.spinner circle {
    fill: none;
    stroke: slategray;
    stroke-width: 16;
    stroke-linecap: round;
    stroke-dasharray: 0, 0, 70, 194;
    stroke-dashoffset: 0;
    animation: spin 1s infinite linear;
    -webkit-animation: spin 1s infinite linear;
}

.loading-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.loading-cover-center {
    right: 0;
}

.loading-cover-cell {
    padding: 0.75rem;
}