@import "bootstrap-overrides";
@import "loading";

/* -- Margin for fixed navbar -- */
.app-body {
    margin-top: 70px;
}

/* -- Margin for iconified menu --*/
.dropdown-item svg, .nav-link svg {
    width: 16px !important;
    margin-right: 0.5rem;
}

/* -- Home page layout -- */
.landing-page {
    overflow: hidden;

    .logo {
        color: #28a745;
        font-family: monospace;
        font-size: 8rem;
        text-shadow: 2px 2px 2px green;
    }
}

/* -- Grid Actions -- */
.grid-actions {
    position: relative;
}

/* -- Utilities -- */
.clickable {
    cursor: pointer;
}