/* -- Bootstrap bug causes menu to flash when full sized -- */
.collapsing {
    transition: none !important;
}

.input-group-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}